<template>
  <div class="popup" v-if="loading">
    <div class="content">
      <img :src="loaderGif" alt="Loading..." loading="lazy" />
    </div>
  </div>
</template>


<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRoute , useRouter } from 'vue-router';
import Swal from 'sweetalert2';

const domain = ref('https://www.ebtkarr.com/dashboard/api')

const loaderGif = ref(require('../assets/imgs/loader.gif'))

const loading = ref(true)

const router = useRouter();

onMounted(() => {
  const route = useRoute()

  const bannerData = new FormData();
  bannerData.append('image_banner' ,  window.localStorage.getItem('banner_img'))
  bannerData.append('details' ,  window.localStorage.getItem('banner_text'))
  bannerData.append('transaction_id' , route.query.transaction_id)
  
  const AdData = new FormData();
  const storedImages = JSON.parse(localStorage.getItem('adsImages')) || [];
  storedImages.forEach((image, index) => {
    AdData.append(`ads_image[${index}]`, image);
  });
  AdData.append('name' , window.localStorage.getItem('ad_name'))
  AdData.append('price' , window.localStorage.getItem('ad_price'))
  AdData.append('category_id' , window.localStorage.getItem('ad_category_id'))
  AdData.append('city_id' , window.localStorage.getItem('ad_city_id'))
  AdData.append('details' , window.localStorage.getItem('ad_details'))
  // AdData.append('duration' , window.localStorage.getItem('ad_duration'))
  AdData.append('contact_method' , window.localStorage.getItem('ad_contact_method'))
  AdData.append('status' , window.localStorage.getItem('ad_status'))
  AdData.append('transaction_id' , route.query.transaction_id)

  if(window.localStorage.getItem('bannerOrAd') === 'banner'){
    axios
      .post(`${domain.value}/add-banner`, bannerData , {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // Get the token from localStorage
        const token = localStorage.getItem('token');

        // Clear all items from localStorage
        localStorage.clear();

        // Set the token back into localStorage
        if (token) {
          localStorage.setItem('token', token);
        }
        if (res.data.key === "success") {
          loading.value=false
          // Hide any modal backdrops if present
          document.querySelectorAll(".modal-backdrop").forEach((element) => {
            element.classList.remove("show");
            element.style.display = "none";
          });
          
          // // Store response data in localStorage
          // localStorage.setItem("AdvertisingTax", res.data.data.AdvertisingTax);
          // localStorage.setItem("banner_price", res.data.data.banner_price);
          // localStorage.setItem("final_total", res.data.data.final_total);
          // localStorage.setItem("banner_id", res.data.data.banner_id);
          
          // Redirect to the banner view page
          setTimeout(() => {
            router.push("/bannerView");
          }, 1000);
        } else {
          // Show an error message if the response is not successful
          Swal.fire({
            icon: "error",
            title: res.data.msg,
            timer: 2000,
            showConfirmButton: false,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err.data.msg,
          timer: 2000,
          showConfirmButton: false,
        });
        setTimeout(() => {
          useRouter().push('/')
        }, 800);
      });
  }else{
    axios
      .post(`${domain.value}/add-advertisement`, AdData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        loading.value = false
        // Get the token from localStorage
        const token = localStorage.getItem('token');

        // Clear all items from localStorage
        localStorage.clear();

        // Set the token back into localStorage
        if (token) {
          localStorage.setItem('token', token);
        }
        if (res.data.key === "success") {
          Swal.fire({
            icon: "success",
            title: 'تم اضافة الاعلان بنجاح',
            timer: 2000,
            showConfirmButton: false,
          });

          // localStorage.setItem("random_token", res.data.data.random_token);
          // localStorage.setItem("ad_price", res.data.data.advertisement_cost);
          // localStorage.setItem("ad_taxt", res.data.data.AdvertisingTax);
          // localStorage.setItem("ad_total", res.data.data.final_total);
          // localStorage.setItem("ad_text", res.data.data.payment_text);
          
          setTimeout(() => {
            router.push("/advertisementsView");
          }, 1000);
        } 
        // else if (res.data.key == "needReActivation") {
        //   Swal.fire({
        //     icon: "error",
        //     title: res.data.msg,
        //     timer: 2000,
        //     showConfirmButton: false,
        //   });

        //   setTimeout(() => {
        //     this.$router.push("/editProfileView");
        //   }, 2000);
        // } 
        else {
          Swal.fire({
            icon: "error",
            title: res.data.msg,
            timer: 2000,
            showConfirmButton: false,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err.data.msg,
          timer: 2000,
          showConfirmButton: false,
        });
        setTimeout(() => {
          useRouter().push('/')
        }, 800);
      });
  }
});
</script>

<style lang="scss" scoped>
  .popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    .content{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      max-width: 500px;
      width: 100%;
      height: 500px;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      overflow: hidden;
      img{
        transform: scale(1.4);
      }
    }
}

.swal2-container.swal2-center>.swal2-popup{
  z-index: 9999999;
}
</style>
